import React from 'react';
import { RefererSummary } from '../../model/StockReferer';
import moment from 'moment';
interface IProps{
    referers: RefererSummary[]
}
const ReferersItem:React.FC<IProps> = (props:IProps) => {
const style:any = { 'fontSize': '0.75rem', 'marginLeft': '1rem'}
return <table>
    <thead>
        <tr>
            <th>Referrer</th>
            <th>Count</th>
            <th>First</th>
            <th>Last</th>
        </tr>
    </thead>
          <tbody>
          {props.referers.map((x, i) => (
        <tr key={i}>
            <td><a href={x.url} target='_blank' rel="noreferrer">{x.url}</a></td>
            <td>{x.count}  </td>
            <td><span style={style}>{moment(x.first).format('DDMMMYY')}</span>  </td>
            <td><span style={style}>{moment(x.last).format('DDMMMYY')}</span>  </td>
              
        </tr>
      ))}
          </tbody>
            
            </table>
}

export default ReferersItem;
