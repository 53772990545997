import React, { useState } from 'react'
import styled from 'styled-components';
import { Note } from '../../../model/Note';
import api from '../../../api/api';
import useAsyncEffect from 'use-async-effect';
import moment from 'moment';
import { GenericHistoryViewModel } from '../../../model/GenericHistoryViewModel';
import GenericHistoryComponent from '../../history/GenericHistoryComponent';
import ReferersItem from '../../referers/ReferersItem';
import { RefererSummary } from '../../../model/StockReferer';

interface IProps {
    ticker: string
}

const Style = styled.div`


.note__date {
font-size: 0.8rem;
}
.note__user{
    font-size: 0.7rem;
    font-style: italic;
    margin-left: 1rem;
    color:#00B5D1
}

.note {
    background-color: lightgray;
    padding: 20px;
    margin-top: 20px;
}

.note__details{
    margin-top:10px;
}
.notes-container{
display:flex;
justify-content: space-between;
}

`;

const NotesComponent: React.FC<IProps> = (props: IProps) => {


    const [notes, setNotes] = useState<Note[]>([]);
    const [history, setHistory] = useState<GenericHistoryViewModel|undefined>();
    const [details, setDetails] = useState<string>('');
    const [saving, setSaving] = useState<boolean>();
    const [ referers, setReferers ] = useState<RefererSummary[]>();
    
    useAsyncEffect(async (isMounted) => {
        let result = await api().getNotes(props.ticker);
        let versionHistory = await api().getStockVersionHistory(props.ticker);
        const refererResult = await api().getReferers(props.ticker);
        if (isMounted()) {
            setNotes(result.notes);
            setHistory(versionHistory);
            if(refererResult.referers.length===1){
                setReferers(refererResult.referers[0].referers);
            }
            
        }

    }, [])

    const addNote = async () => {
        setSaving(true)
        let result = await api().saveNote( { ticker: props.ticker, details });
        setNotes( [ result.note, ...notes  ])
        setDetails('')
        setSaving(false)
    }

    return <Style className="input-group" >

        <div className='notes-container'>
        <div>
            <textarea onChange={e => setDetails(e.target.value)} cols={50} rows={5} value={details}></textarea>
        <div>
            <button onClick={addNote} disabled={saving}>Add Note</button>
        </div>
        </div>
        <div>
         
        </div>
        </div>

       


        {!notes.length && <p>No Notes</p>}
        {!!notes.length && <div className="invoices">
            
            <div>
                {notes.map((x, i) => <div key={x.id}>
                    <div className="note">
                        <span className="note__date">{moment(x.date).format('D MMM YYYY hh:mm A')}</span>
                        <span className="note__user">{x.user}</span>

                        <div className="note__details">{x.details}</div>

                    </div>
                    
                    
                </div>)}
            </div>
        </div>}

        { !!referers && <div>
            <h2>Referers</h2>
            <ReferersItem referers={referers} />
            </div>}

        { !!history && <GenericHistoryComponent view={history}/>}

    </Style>
}

export default NotesComponent;